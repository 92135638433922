import { useRequestHeaders } from 'nuxt/app';
import { onServerPrefetch } from 'vue';
import { breakpointsTailwind, useBreakpoints } from '@vueuse/core';
import { getDeviceType } from './helpers';

export function useDeviceType() {
	const deviceType = useState<string | undefined>(
		'deviceType',
		() => undefined,
	);
	const { md } = useBreakpoints(breakpointsTailwind);

	const determineDeviceType = () => {
		const headers = useRequestHeaders(['user-agent']);
		if (headers['user-agent']) {
			deviceType.value = getDeviceType(headers['user-agent']) ?? 'desktop';
		}
	};

	const isDesktop = () => {
		return deviceType.value === 'desktop' || deviceType.value === 'smarttv';
	};

	onServerPrefetch(determineDeviceType);
	watch(
		md,
		(md) => {
			if (!process.client) return;
			if (isDesktop() && !md) {
				deviceType.value = 'mobile';
			}
			if (!isDesktop() && md) {
				deviceType.value = 'desktop';
			}
		},
		{
			immediate: true,
		},
	);

	return {
		deviceType,
	};
}
